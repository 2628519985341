'use client'
import { useEffect } from 'react'
import { Bokeh1Background } from './bokeh1.min'
import './google-analytics'

async function startBackground() {
  // const { Bokeh1Background } = await import('./bokeh1.min')
  const bokeh1Background = Bokeh1Background(document.getElementById('webgl-canvas'))
  bokeh1Background.loadMap('https://cdn.jsdelivr.net/npm/threejs-components@0.0.2/build/assets/bokeh-particles2.png')
  bokeh1Background.setColors([0x6d4862, 0xfd826c, 0x22ccc1])
  document.body.addEventListener('click', () => {
    bokeh1Background.setBackgroundColor(0xffffff * Math.random())
    bokeh1Background.setColors([0xffffff * Math.random(), 0xffffff * Math.random(), 0xffffff * Math.random()])
  })
}

export default function Home() {
  useEffect(() => {
    void startBackground()
  }, [])

  return (
    <div id="app">
      <canvas id="webgl-canvas"></canvas>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: 50,
          borderRadius: 10,
          // background: 'rgba(255, 255, 255, 0.4)',
          // background: 'rgba(0, 0, 0, 0.4)',
          background: 'rgba(128,128,128, 0.4)',
          textShadow: '0 0 10px rgba(0, 0, 0, 0.75)',
        }}
      >
        <h1>Agentic Minds</h1>
        <div style={{ fontSize: 27, paddingTop: 30 }}>Collaborative AI for a Smarter World</div>
      </div>
    </div>
  )
}
