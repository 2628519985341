// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, isSupported } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyA9su3F022d1tvf1ZEJiZtA4COGw7aDEnM',
  authDomain: 'ag-landing.firebaseapp.com',
  projectId: 'ag-landing',
  storageBucket: 'ag-landing.appspot.com',
  messagingSenderId: '302643109440',
  appId: '1:302643109440:web:3c48eca6eda5eacade09a5',
  measurementId: 'G-FG1RS26W0T',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = isSupported().then(yes => (yes ? getAnalytics(app) : null))
